<!-- 好友信息 -->
<template>
  <div v-if="info" class="Info-wrapper">
    <!-- <div
      v-show="info.list_id === ''"
      class="newfriend"
    >
      <div class="nickname">{{ info.nickname }}</div>
    </div> -->
    <div class="friendInfo">
      <div class="esInfo">
        <div class="left">
          <div class="people">
            <div class="nickname">{{ info.nickname }}</div>
            <div
              :class="[info.sex === 1 ? 'gender-male' : 'gender-female']"
            ></div>
          </div>
          <div class="signature">{{ info.signature }}</div>
        </div>
        <div class="right">
          <img
            class="avatar"
            width="60"
            height="60"
            :src="photoUrl + info.photo"
          />
        </div>
      </div>
      <div class="detInfo">
        <div class="remark">
          <span>备&nbsp&nbsp&nbsp注</span>
          <span
            title="点击修改备注"
            style="cursor: pointer"
            @click="changeRemark(info)"
            >{{ info.remark }}
          </span>
        </div>
        <div class="area">
          <span>来&nbsp&nbsp&nbsp源</span><span>{{ info.from }}</span>
        </div>
        <div class="list_id">
          <span>微信号</span><span>{{ info.username }}</span>
        </div>
      </div>

      <div class="option">
        <div class="send" @click.stop="send">
          <span>发消息</span>
        </div>
        <div class="send" @click.stop="handleShare">
          <span>分享名片</span>
        </div>
        <div
          v-if="groupItem && groupItem.group.id"
          class="send"
          style="margin-left: 30px"
          @click.stop="handleRemoveGroup"
        >
          <span>移除分组</span>
        </div>
      </div>
    </div>
    <selectUser
      ref="selectUser"
      title="分享名片"
      tip="分别转发给"
      :multiple="false"
      @handleOk="handleOk"
    ></selectUser>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import selectUser from "@/components/selectUser";
import { setRemarks, fetchInfo, sendCardInfo,removeUserFromGroup } from "@/api";
const { photoUrl } = window.__gconf;
export default {
  components: {
    selectUser,
  },
  props: {
    groupItem: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      info: null,
      photoUrl: photoUrl,
    };
  },
  computed: {
    ...mapGetters(["selectedFriend"]),
  },
  watch: {
    selectedFriend(val) {
      this.fetchInfo();
    },
  },
  methods: {
    ...mapActions(["selectSession", "fetchFriendList", "fetchGroupList"]),
    handleRemoveGroup() {
      console.log(this.groupItem);
      this.$confirm(
        `确定将"${this.info.nickname}"移除${this.groupItem.group.name}分组吗?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(async () => {
        await removeUserFromGroup({
          userids:this.selectedFriend.id,
          groupId: this.groupItem.group.id,
          _token: localStorage.getItem("token"),
          _agent_id: 1,
        }).then((res) => {
          this.fetchGroupList();
          this.$message({
            type: "success",
            message: "移除分组成功!",
          });
        });
      });
    },
    handleOk(data) {
      sendCardInfo({
        users: JSON.stringify(
          data.map((item) => {
            return item.user_id;
          })
        ),
        toUser: this.info.user_id,
      }).then((res) => {
        this.$message.success("发送成功");
      });
    },
    fetchInfo() {
      console.log("this.selectedFriend", this.selectedFriend)
      fetchInfo({
        user_id: this.selectedFriend.id,
        in: 0,
      }).then((res) => {
        this.info = {
          ...this.selectedFriend,
          ...res.data,
        };
      });
    },
    send() {
      this.$store.dispatch("send");
      this.$store.dispatch("search", "");
    },
    handleShare() {
      this.$refs.selectUser.open();
    },
    changeRemark(item) {
      this.$prompt("请输入备注信息", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputValue: item.remark,
        inputErrorMessage: "备注信息不能为空",
      })
        .then(({ value }) => {
          setRemarks({
            content: value,
            user_id: item.id,
          }).then((res) => {
            this.fetchFriendList();
          });
        })
        .catch(() => {});
      console.log(item);
    },
  },
};
</script>

<style lang="stylus" scoped>
.newfriend
  height 60px
  padding 28px 0 0 30px
  box-sizing border-box
  border-bottom 1px solid #e7e7e7
  .nickname
    font-size 18px
.friendInfo
  padding 0 90px
.esInfo
  display flex
  align-items center
  padding 100px 0 45px 0
  .left
    flex 1
    .people
      .nickname
        display inline-block
        font-size 20px
        margin-bottom 16px
      .gender-male, .gender-female
        display inline-block
        width 18px
        height 18px
        vertical-align top
        margin-top 2px
      .gender-male
        background-image url('man.png')
        background-size cover
      .gender-female
        background-image url('woman.png')
        background-size cover
    .signature
      font-size 14px
      color rgba(153, 153, 153, 0.8)
  .right
    .avatar
      border-radius 3px
.detInfo
  padding 40px 0
  border-top 1px solid #e7e7e7
  border-bottom 1px solid #e7e7e7
  .remark, .area, .list_id
    font-size 14px
    margin-top 20px
    span
      font-size 14px
      color rgba(153, 153, 153, 0.8)
      margin-right 40px
      &:last-child
        margin-right 0
  .remark
    margin-top 0
.option
  display flex
  justify-content center
  align-items center
  margin-top 40px
.send
  text-align center
  width 140px
  height 36px
  line-height 36px
  font-size 14px
  color #fff
  background-color #007AFF
  cursor pointer
  border-radius 2px
  &:first-child
    margin-right 30px
  &:hover
    background #2975c4
</style>
