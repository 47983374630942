<!-- 好友列表 -->
<template>
  <div class="friendlist">
    <div
      class="friend-info static"
      :class="{ active: type == 1 }"
      @click="handleShowNew"
    >
      <img class="avatar" width="36" height="36" :src="img" />
      <div class="remark">新的朋友</div>
      <p v-if="user.new_friend_tips_num > 0" class="newFriend">
        {{ user.new_friend_tips_num }}
      </p>
    </div>
    <!-- 分组 -->
    <ul>
      <li class="frienditem">
        <div class="list_title">分组</div>
      </li>
    </ul>
    <ul>
      <li
        v-for="(it, index) in groupList"
        :key="index"
        class="frienditem"
        :class="{ noborder: !it.initial }"
      >
        <div
          class="list_title"
          style="display: flex; justify-content: space-between; cursor: pointer"
          @click="it.show = !it.show"
        >
          <div>
            {{ it.group.name }}
            <i class="el-icon-delete" @click.stop="handleDeleteGroup(it)"></i>
          </div>
          <div style="margin-right: 6px">
            <!-- 展开收起 -->
            <i :class="it.show ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" />
          </div>
        </div>
        <div v-for="(item, index) in it.users" :key="index" v-show="it.show">
          <!-- <div
            v-if="
              item.initial &&
              (index == 0 ||
                it.users[index - 1].initial != item.initial)
            "
            class="list_title"
          >
            {{ item.initial }}
          </div> -->
          <div
            class="friend-info"
            :class="{ active: item.id === selectFriendId && type == 2 }"
            @click="handleSelectGroupFriend(item.id, it)"
          >
            <img class="avatar" width="36" height="36" :src="item.img" />
            <div class="remark">{{ item.remark }}</div>
          </div>
        </div>
      </li>
    </ul>
    <!-- 群聊 -->
    <ul>
      <li class="frienditem">
        <div class="list_title">群聊</div>
        <div
          v-for="(item, index) in list"
          :key="index"
          class="friend-info"
          :class="{ active: item.chat_id === selectId && type == 3 }"
          @click="handleShowGroup(item)"
        >
          <img
            class="avatar"
            width="36"
            height="36"
            :src="photoUrl + item.photo_path"
          />
          <div class="remark">{{ item.show_name }}</div>
        </div>
      </li>
    </ul>
    <!-- 用户 -->
    <ul>
      <li
        v-for="(item, index) in searchedFriendlist"
        :key="index"
        class="frienditem"
        :class="{ noborder: !item.initial }"
      >
        <div
          v-if="
            item.initial &&
            (index == 0 ||
              searchedFriendlist[index - 1].initial != item.initial)
          "
          class="list_title"
        >
          {{ item.initial }}
        </div>
        <div
          class="friend-info"
          :class="{ active: item.id === selectFriendId && type == 2 }"
          @click="handleSelectFriend(item.id)"
        >
          <img class="avatar" width="36" height="36" :src="item.img" />
          {{ item }}
          <div class="remark">{{ item.remark }}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { getFriendList, getChatList } from "@/api";
import img from "@/assets/images/friend.png";
const { photoUrl } = window.__gconf;
import { delGroup } from "@/api";
export default {
  props: {
    type: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState(["selectFriendId", "searchText", "user", "groupList"]),
    ...mapGetters(["searchedFriendlist"]),
  },
  data() {
    return {
      img,
      list: [],
      selectId: null,
      photoUrl: photoUrl,
    };
  },
  mounted() {
    console.log("this.groupList", this.groupList);
    this.fetchFriendList();
    this.fetchGroupList();
    getChatList({
      type: 1,
    }).then((res) => {
      console.log(res);
      this.list = res.data;
    });
  },
  methods: {
    ...mapActions(["selectFriend", "fetchFriendList", "fetchGroupList"]),
    handleSelectFriend(id) {
      this.$emit("handleClick", 2);
      this.selectFriend(id);
    },
    handleSelectGroupFriend(id, it) {
      this.$emit("handleClick", 4, it);
      this.selectFriend(id);
    },
    handleShowNew() {
      this.$emit("handleClick", 1);
    },
    handleShowGroup(item) {
      this.selectId = item.chat_id;
      this.$emit("handleClick", 3, item);
    },
    handleDeleteGroup(item) {
      console.log(item);
      this.$confirm(`确定删除“${item.group.name}”分组吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await delGroup({
          group_id: item.group.id,
          _token: localStorage.getItem("token"),
          _agent_id: 1,
        }).then((res) => {
          console.log(res);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.fetchGroupList();
        });
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.newFriend
  position absolute
  right 20px
  top 22px
  height 15px
  line-height 15px
  min-width 15px
  text-align center
  background-color #fff
  border-radius 50px
  box-sizing border-box
  color #e1e1e1
  font-size 11px
.friendlist
  height calc(100% - 62px)
  overflow-y auto
  background #fff
  .frienditem
    border-top 1px solid #f2f2f2
    &:first-child, &.noborder
      border-top none
    .list_title
      box-sizing border-box
      width 100%
      font-size 12px
      padding 15px 0 3px 12px
      color #999
.friend-info
  display flex
  padding 12px
  transition background-color 0.1s
  font-size 0
  cursor pointer
  &:hover
    background-color #eee
  &.active
    background-color #eee
  .avatar
    border-radius 2px
    margin-right 12px
  .remark
    font-size 14px
    line-height 36px
.static
  border-bottom 1px solid #f2f2f2
  position relative
</style>
